import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    console.log('service token is');
    console.log(serviceToken);

    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        console.log('service token added');
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
        console.log('service token removed');
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                console.log('========service token is');
                console.log(serviceToken);

                if (serviceToken != null) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }

                // if (serviceToken && verifyToken(serviceToken)) {
                //     setSession(serviceToken);
                //     const response = await axios.get('/login');
                //     const { user } = response.data;
                //     dispatch({
                //         type: LOGIN,
                //         payload: {
                //             isLoggedIn: true,
                //             user
                //         }
                //     });
                // } else {
                //     dispatch({
                //         type: LOGOUT
                //     });
                // }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };
        init();
    }, []);

    const login = async (email, password, firebase_token) => {
        const response = await axios.post('/login', { email, password, firebase_token });
        if (response.data.status === true) {
            // console.log('login sucessfull====');
            // console.log(response.data.token);
            const token = response.data.token;
            setSession(token);
            const employee_id = response.data.employee_id;
            localStorage.setItem('employee_id', employee_id);
            const user_id = response.data.user_id;
            localStorage.setItem('user_id', user_id);
            localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    message: response.data.message,
                    user: response.data.user,
                    token: token,
                    error: false
                }
            });
        } else {
            console.log('login failes====');
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: false,
                    message: response.data.message,
                    user: null,
                    token: null,
                    error: true
                }
            });
        }
    };

    const register = async (data) => {
        const response = await axios.post('/register', data);
        return response;
    };
    const registerSuccess = (responseData) => {
        if (responseData.status === true) {
            console.log('Register sucessfull====');
            console.log(responseData.token);
            const token = responseData.token;
            setSession(token);
            const employee_id = responseData.employee_id;
            localStorage.setItem('employee_id', employee_id);
            const user_id = responseData.user_id;
            localStorage.setItem('user_id', user_id);
            localStorage.setItem('permissions', JSON.stringify(responseData.permissions));
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    message: responseData.message,
                    user: responseData.user,
                    token: token,
                    error: false
                }
            });
        } else {
            console.log('Register failes====');
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: false,
                    message: responseData.message,
                    user: null,
                    token: null,
                    error: true
                }
            });
        }
    };
    const registerWithGoogle = async (profileObject) => {
        dispatch({
            type: LOGIN,
            payload: {
                error: false
            }
        });
        // todo: this flow need to be recode as it not verified
        const response = await axios.post('/register-with-google', profileObject);
        if (response.data.status === true) {
            console.log('Register sucessfull====');
            console.log(response.data.token);
            const token = response.data.token;
            setSession(token);
            const employee_id = response.data.employee_id;
            localStorage.setItem('employee_id', employee_id);
            const user_id = response.data.user_id;
            localStorage.setItem('user_id', user_id);
            localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    message: response.data.message,
                    user: response.data.user,
                    token: token,
                    error: false
                }
            });
        } else {
            console.log('login failes====');
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: false,
                    message: response.data.message,
                    user: null,
                    token: null,
                    error: true
                }
            });
        }
    };

    const loginWithGoogle = async (profileObject) => {
        // todo: this flow need to be recode as it not verified
        dispatch({
            type: LOGIN,
            payload: {
                error: false
            }
        });
        const response = await axios.post('/login-with-google', profileObject);
        if (response.data.status === true) {
            console.log('Login sucessfull====');
            const token = response.data.token;
            setSession(token);
            const employee_id = response.data.employee_id;
            localStorage.setItem('employee_id', employee_id);
            const user_id = response.data.user_id;
            localStorage.setItem('user_id', user_id);
            localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    message: response.data.message,
                    user: response.data.user,
                    token: token,
                    error: false
                }
            });
        } else {
            console.log('login failes====');
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: false,
                    message: response.data.message,
                    user: null,
                    token: null,
                    error: true
                }
            });
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
        localStorage.clear();
    };

    const resetPassword = async (email) => {
        localStorage.setItem('forget-mail', email);
        const response = await axios.post('/forgot/password', { email });
        return response;
    };

    const verifyOtp = async (data) => {
        // localStorage.setItem('forget-mail',email);
        const response = await axios.post('/verify/otp', data);
        return response;
    };

    const otpVerificationSuccess = (data) => {
        localStorage.removeItem('forget-mail');
        localStorage.setItem('forget-email-token', data.token);
    };

    const changePassword = async (data) => {
        let tokenStr = localStorage.getItem('forget-email-token');
        const response = await axios.post('/change/password', data, { headers: { Authorization: `Bearer ${tokenStr}` } });
        return response;
    };
    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                registerSuccess,
                loginWithGoogle,
                registerWithGoogle,
                resetPassword,
                verifyOtp,
                otpVerificationSuccess,
                changePassword,
                updateProfile
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
